/*373 172*/
#logo-home {
    border-radius: 15%;
    width: 373px;
    height: 172px;
}

#logo-navbar {
    border-radius: 15%;
    width: 93px;
    height: 43px;
}

#faq-page {
    padding-top: 75px;
}

#next-steps {
    border-style: solid;
    border-color: #215B90;
    margin: 0px;
}

#question {
    border-style: solid;
    border-color: #215B90;
    margin: 0px;
}