@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 400px) {
  #howItWorks {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

/*373 172*/
#logo-home {
    border-radius: 15%;
    width: 373px;
    height: 172px;
}

#logo-navbar {
    border-radius: 15%;
    width: 93px;
    height: 43px;
}

#faq-page {
    padding-top: 75px;
}

#next-steps {
    border-style: solid;
    border-color: #215B90;
    margin: 0px;
}

#question {
    border-style: solid;
    border-color: #215B90;
    margin: 0px;
}
.postcard {
    border-radius: 25px;
    border: 2px solid #000000;
    color: white;
    background-color: #235F97;
    /* max-width: 390px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 5%;
    padding-left: 5%;

    margin-bottom: 100px;
}



.postcard-main-image {
    border-radius: 25px;
    width: 100%;
    height: auto;
}

.postcard-author-image {
    height: auto;
    max-height: 30px;
    width: auto;
    max-width: 100px;
    margin-right: 5px;
}

.calendar-svg {
    height: auto;
    max-height: 25px;
    width: auto;
    max-width: 100px;
    margin-left: 50px;
    margin-right: 5px;
    margin-top: 0px;

    display: -webkit-inline-flex;

    display: inline-flex;
    -webkit-align-self: center;
            align-self: center;

    top: .280em;
    position: relative;
    
}

.widget {
    border-radius: 25px;
    border: 2px solid #000000;
    color: white;
    background-color: #235F97;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 5%;
    padding-left: 5%;

    margin-bottom: 100px;
}

.widget-date {
    margin: 0px;
    padding-bottom: 0px;
    color: black;
}

.widget-image {
    border-radius: 25px;
    width: auto;
    height: auto;
    max-height: 50px;
}

.widget-post {
    margin-bottom: 10px;
}

.widget-category {
    margin-bottom: 10px;
    font-size: 18px;
}

.whiteText {
    color: white;
}


.link {
    color: #FFF;
}
.link:hover {
    color: gold
}

.link:focus {     
    color: #FFF;  
}

.author {
    border-radius: 25px;
    border: 2px solid #000000;
    background: rgb(35, 95, 151); /* Fallback for older browsers without RGBA-support */
    background: rgba(35, 95, 151, 0.75);
    color: white;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    margin-bottom: 50px;
}

.author-image {
    height: 100px;
    width: auto;
    border-radius: 50%;

    position: relative;
    top: -50;
}

.author-text {
    position: relative;
    top: -50;
}

.comment-form {
    border-radius: 25px;
    border: 2px solid #000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: lightgray;
    color: black;
    padding: 20px;
    margin-bottom: 50px;
}


.form-fields {
    margin-bottom: 20px;
    color: black;
    font-size: 30px;
    
}

.form-control {
    resize:none;
}

.form-control:focus{
    color:black;
 }

.comment-error-message {
    color: red;
    font-size: 20px;
}

.comment-success-message{
    color: black;
    font-size: 20px;

}

.comments-display {
    border-radius: 25px;
    border: 2px solid #000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: lightgray;
    color: black;
    padding: 20px;
}

.comment-single {
    margin-bottom: 30px;
}

.comment-font {
    font-size: 13px;
}

@tailwind base;@tailwind components;@tailwind utilities;html,body{padding:0;margin:0;font-family:"Montserrat",sans-serif}html:before,body:before{content:"";content:"";width:100%;height:100vh;position:fixed;left:0;top:0;z-index:-1;background-position:50% 50%;background-repeat:no-repeat;background-size:cover}.text-shadow{text-shadow:0px 2px 0px rgba(0,0,0,.3)}.adjacent-post .arrow-btn{transition:width 300ms ease;width:50px}.adjacent-post:hover .arrow-btn{width:60px}.react-multi-carousel-list .arrow-btn{transition:width 300ms ease;width:50px}.react-multi-carousel-list .arrow-btn:hover{width:60px}a{color:inherit;text-decoration:none}*{box-sizing:border-box}
